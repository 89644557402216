import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import { css } from "@emotion/core"

import { switchPage } from "src/utils/navigateTo"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title } from "src/componentsV2/sections/Text"
import { ButtonTransparent } from "src/componentsV2/ui/Button"
import { plans, PlansProps } from "src/componentsV2/shared/plansData"

const Enterprise = "Enterprise"

const CostSectionContainerStyled = styled(ContainerPage)`
  padding-top: 30px;

  @media (min-width: 767px) {
    padding-top: 180px;
    margin-top: -270px;

    .title {
      ${tw`text-center`}
    }
  }
`

const EmployeeRange = styled(({ className, children }: any) => (
  <section className={className}>
    {children}
    {" employees"}
  </section>
))`
  margin-bottom: 20px;
`

const Cost = styled(
  ({ className, plan }: { className: any; plan: PlansProps }) => {
    return (
      <section className={className}>
        <span className="text-22/16 mr-1 absolute left-0 top-0 pt-2">$</span>
        <span className="font-black ml-6">
          <span className="text-50/16">{plan.costYearTotal}</span>
          <span className="text-sm font-medium text-gray-1">{`/yr`}</span>
        </span>
      </section>
    )
  }
)``

const CostSection = () => {
  return (
    <CostSectionContainerStyled data-loadable-component="cost-section">
      <WrapperPage>
        <Title className="title md:text-center">How much does it cost?</Title>
        <div
          className="flex items-center justify-around flex-wrap flex-col 
          md:flex-row md:max-w-210 mx-auto mb-0 md:mb-6"
        >
          {plans.map((plan, index) => (
            <div
              className="text-black text-center w-full md:w-1/3 md:mb-0 mb-15"
              css={css`
                &:last-child {
                  ${tw`mb-6 md:mb-0`};
                }
              `}
              key={index}
            >
              <div className="text-xl	md:text-2xl">{plan.name}</div>

              <EmployeeRange className="text-base mt-3">
                {plan.employeeRange ? (
                  <b>{plan.employeeRange}</b>
                ) : (
                  <span>
                    <b>{plan.minEmployeeRange}</b> to{" "}
                    <b>{plan.maxEmployeeRange}</b>
                  </span>
                )}
              </EmployeeRange>
              {plan.name !== Enterprise ? (
                <Cost plan={plan} className="mt-4 text-blue-darker" />
              ) : (
                <div className="text-3xl text-blue-darker font-bold leading-9.25 pt-0 md:h-14.2 md:leading-14.2">
                  Custom
                </div>
              )}
              <div className="text-gray-1 text-sm	mt-4 h-auto md:h-8">
                {plan.name === Enterprise && (
                  <div className="mx-auto leading-6 md:max-w-7/2">
                    Enterprise-grade, custom-tailored for global organizations
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
        <ButtonTransparent
          className="mx-auto mt-2"
          onClick={switchPage.plans}
          css={css`
            ${tw`text-base`}
            padding: 0 25px;
          `}
        >
          View our pricing plans
        </ButtonTransparent>
      </WrapperPage>
    </CostSectionContainerStyled>
  )
}

export default CostSection
